import queryString from 'query-string';
import { MouseEvent, useCallback } from 'react';
import { useLocation } from 'react-router';

import { useDeferredNavigate } from 'hooks/useDeferredNavigate';
import { getParsedSearchQuery } from 'modules/search/helpers/getParsedSearchQuery';
import { SearchPageLocationState } from 'modules/search/pages/Search/types/SearchPageLocationState';
import { searchGetRoutePathSwitch } from 'modules/search/routing/helpers/searchGetRoutePathSwitch';
import { SearchLocation } from 'modules/search/types/SearchLocation';
import { SearchType } from 'modules/search/types/SearchType';
import {
  getSearchLocation,
  updateSearchLocation,
  useSearchLocation,
} from 'modules/search/zustand-stores/searchStore';
import { trackClicked } from 'utils/analytics/track/trackClicked';
import { EMPTY_SEARCH_LOCATION } from 'utils/constants/general/emptySearchLocation';
import { deferAndStartRouteLoadingAnimation } from 'utils/ui/routeLoadingAnimation';
import { showModal } from 'zustand-stores/modalStore';
import { useUserEnvironment } from 'zustand-stores/userEnvironmentStore';

type Args = {
  searchFrom: string | undefined;
};

export function useSearchHero({ searchFrom }: Args) {
  const location = useLocation();
  const navigate = useDeferredNavigate();

  const { user } = useUserEnvironment();

  const searchLocation = useSearchLocation();

  const initialQuery = '';

  const changeSearchLocation = (
    newSearchLocation: SearchLocation | undefined,
  ): boolean => {
    if (newSearchLocation) {
      // The LocationInput triggers the change event twice except for the `Remote` option
      if (
        newSearchLocation.text &&
        getSearchLocation()?.text === newSearchLocation.text
      )
        return false;

      deferAndStartRouteLoadingAnimation(() => {
        updateSearchLocation(newSearchLocation, true);
      });
    } else {
      updateSearchLocation(EMPTY_SEARCH_LOCATION, true);
    }

    return true;
  };

  const search = useCallback(
    (data: {
      query: string;
      listingType: SearchType;
      triggeredByRemoteLocationChange: boolean;
    }) => {
      const { parsedQuery, locationType, remoteKeywords } =
        getParsedSearchQuery({ query: data.query });

      const path = searchGetRoutePathSwitch({
        lang: CURRENT_LOCALE,
        q: parsedQuery,
        filtersByName: {
          type: data.listingType,
          locationType: data.triggeredByRemoteLocationChange
            ? ['REMOTE']
            : locationType,
        },
      });

      navigate(path, {
        state: { searchFrom, remoteKeywords } satisfies SearchPageLocationState,
      });
    },
    [navigate, searchFrom],
  );

  const redirectTo = location ? `${location.pathname}${location.search}` : '';
  const redirectParam = queryString.stringify({ to: redirectTo });

  const clickLogin = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    trackClicked('Log In Search Hero');
    showModal('LOGIN', { redirectTo });
  };

  const clickSignup = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    trackClicked('Sign Up Search Hero');
    showModal('SIGNUP', { redirectTo });
  };

  return {
    initialQuery,
    redirectParam,
    search,
    searchLocation,
    changeSearchLocation,
    clickLogin,
    clickSignup,
    showLogin: !user,
  };
}
